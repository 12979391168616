<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <section class="search__container">
      <common-search :fields="fields" @search="onSearch"></common-search>
    </section>
    <section class="table__container">
      <a-table
        :columns="tblColumns"
        :data-source="tblData"
        :loading="loading"
        :pagination="pagination"
        rowKey="orderSn"
        @change="onPage"
      >
        <template slot="signStatusName">
          签署状态
          <a-tooltip>
            <template slot="title">
              订单合同的签署状态
            </template>
            <a-icon type="info-circle" />
          </a-tooltip>
        </template>
        <template slot="createTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="onDetail(record.orderSn)">查看</a>
        </template>
      </a-table>
    </section>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { tsFormat } from "@/components/DateUtils";
import {
  changeOrderListOperation,
  fetchCorporationInfoByKeyword,
  fetchSellerCorporationByKeyword
} from "@/services/OrderManageService";
import { pageDetailMixins } from "@/components/pageDetailMixin";

const tblColumns = [
  {
    title: "订单编号",
    dataIndex: "orderSn",
    width: "15%"
  },
  {
    title: "客户",
    dataIndex: "purchaserCorporationName",
    width: "10%"
  },
  {
    title: "商品数量",
    dataIndex: "totalQuantity",
    width: "10%"
  },
  {
    title: "商家",
    dataIndex: "producerCorporationName",
    width: "10%"
  },
  {
    title: "原订单号",
    dataIndex: "orderPurchaseSn",
    width: "15%"
  },
  {
    title: "订单状态",
    dataIndex: "orderStatusValue",
    width: "10%"
  },
  {
    dataIndex: "signStatus",
    slots: { title: "signStatusName" },
    width: "10%"
  },
  {
    title: "申请时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "15%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "5%"
  }
];

export default {
  name: "ChangeOrderListOperation",
  components: {
    Breadcrumb
  },
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "订单编号",
          key: "orderSn",
          component: "j-input"
        },
        {
          label: "客户",
          key: "purchaserDistributorId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchCorporation(value);
          }
        },
        {
          label: "商家",
          key: "factoryId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchSellerCorporation(value);
          }
        },
        {
          label: "原订单号",
          key: "purchaseOrderSn",
          component: "j-input"
        },
        {
          label: "订单状态",
          key: "orderStatus",
          component: "j-select",
          defaultValue: "all",
          options: [
            {
              label: "全部",
              value: "all"
            },
            {
              label: "商家审核中",
              value: "pro_to_audit"
            },
            {
              label: "签约中",
              value: "signing"
            },
            {
              label: "客户发货中",
              value: "pur_to_deliver"
            },
            {
              label: "客户收货中",
              value: "pur_to_receive"
            },
            {
              label: "商家发货中",
              value: "pro_to_deliver"
            },
            {
              label: "商家收货中",
              value: "pro_to_receive"
            },
            {
              label: "已完成",
              value: "complete"
            },
            {
              label: "已取消",
              value: "canceled"
            },
            {
              label: "已退回",
              value: "returned"
            }
          ]
        },
        {
          label: "签署状态",
          key: "signStatus",
          component: "j-select",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "待签署",
              value: "false"
            },
            {
              label: "已签署",
              value: "true"
            }
          ]
        },
        {
          label: "申请时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        }
      ],
      form: {
        orderStatus: "all"
      },
      tblColumns,
      tblData: [],
      loading: false,
      pagination: { showQuickJumper: true },
      tsFormat
    };
  },
  activated() {
    this.loadList();
  },
  methods: {
    // 搜索下单机构
    async onSearchCorporation(value) {
      let options = [];
      const resp = await fetchCorporationInfoByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          options.push({
            value: item.distributorId,
            label: `${item.corporationName}(${item.distributorTypeValue})`
          });
        });
      }
      return options;
    },
    // 搜索卖方机构
    async onSearchSellerCorporation(value) {
      let options = [];
      const resp = await fetchSellerCorporationByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          options.push({
            value: item.distributorId,
            label: `${item.corporationName}(${item.distributorTypeValue})`
          });
        });
      }
      return options;
    },
    // 加载列表
    loadList() {
      this.loading = true;
      const params = {
        orderSn: this.form.orderSn || "",
        purchaseOrderSn: this.form.purchaseOrderSn || "",
        purchaserDistributorId: this.form.purchaserDistributorId || "",
        displaceSendCorporationId: this.form.displaceSendCorporationId || "",
        orderStatus: this.form.orderStatus || "",
        signStatus: this.form.signStatus || "",
        startTime: this.form.startTime ? this.form.startTime : "",
        endTime: this.form.endTime ? this.form.endTime : "",
        orderCategory: "EXCHANGE",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      changeOrderListOperation(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.tblData = data.records;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
        }
      });
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 查看详情
    onDetail(orderSn) {
      this.$router.push({
        name: "ChangeOrderDetailOperation",
        params: { orderSn: orderSn }
      });
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item .ant-form-item-label {
  min-width: 72px !important;
  text-align: left !important;
}

/deep/ .ant-form-item {
  margin-right: 40px;
}
</style>
